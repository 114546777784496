export const OAUTH2_IMPLICIT = 'OAUTH2_IMPLICIT';
export const OAUTH2_IMPLICIT_PENDING = 'OAUTH2_IMPLICIT_PENDING';
export const OAUTH2_IMPLICIT_FULFILLED = 'OAUTH2_IMPLICIT_FULFILLED';
export const OAUTH2_IMPLICIT_REJECTED = 'OAUTH2_IMPLICIT_REJECTED';

export const OAUTH2_CODE_TOKEN = 'OAUTH2_CODE_TOKEN';
export const OAUTH2_CODE_TOKEN_PENDING = 'OAUTH2_CODE_TOKEN_PENDING';
export const OAUTH2_CODE_TOKEN_FULFILLED = 'OAUTH2_CODE_TOKEN_FULFILLED';
export const OAUTH2_CODE_TOKEN_REJECTED = 'OAUTH2_CODE_TOKEN_REJECTED';

export const OAUTH2_LOGOUT = 'OAUTH2_LOGOUT';
